.media-window-body {
  margin: 0;
  height: 100%;
  background-color: #F2F2F2 !important;
}

.media-box {
  box-sizing: border-box;
	background-repeat: repeat;
  //z-index: 999;
  //background-color: white;
  //border: 2px solid #000000;
  height: 100%;
  min-height:100%;
  .media-footer {
    position: absolute;
    bottom: 0;
    background-color: white;
    width: 100%;
    .title{
      height:30px;
      width: 100%;
      font-weight: bold;
      font-size: 18px;
      display: flex;
      .date-txt{
        margin : auto;
      }
      .close-btn{
        justify-content: right;
        margin:auto;
        margin-right: 5px;
      }
    }
  }
  .img-video-container {
    background-color: #F2F2F2;
    .img-video {
      padding-top : 30px;
      padding-bottom: 0px;
      display: block;
      object-fit: contain;
      margin: auto; /* 完全に中央に配置するため、上・左の margin を負の数に設定 */
    }
  }
}

.thumbnail-container {
  box-sizing: border-box;
	//background-repeat: repeat;
  //z-index: 999;
  //background-color: white;
  //border: 2px solid #000000;
  //display: flex;
  //flex-wrap : wrap;
  // flex-direction: row;
  // justify-content:flex-start; 
  .title{
    height:30px;
    width: 100%;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    .date-txt{
      margin-left : 10px;
    }
  }
  .img-set-container {
    display: flex;
    flex-wrap : wrap;
    justify-content:flex-start; 
    background-color: #F2F2F2;
    // min-height:500;
    // height:2000px;
    .img-set {
      max-width: 100%;
      height:150px;
      width: 100px;
      object-fit: contain;
      margin : 10px;
      img {
        height:100px;
        width: 100px;
      }
      .icon-set {
        display: flex;
        justify-content: space-between;
        .download-btn{
          margin-right:5px;
        }
        .delete-btn{
          margin-left:5px;
        }
      }
      
    }
  }

}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: $primary;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  .login-container {
    background-color: $white;
    border-radius: $radius;
    box-shadow: $box-shadow;
    padding: 60px 60px 30px 60px;
    max-width: 540px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    .ant-form-item-explain-error {
      text-align: left !important;
    }
    .logo {
      height: 35px;
      margin-bottom: 40px;
    }
    .button {
      background-color: $semiPrimary !important;
      border: 1px solid $semiPrimary !important;
      border-radius: $radiusXs;
    }
    .end {
      margin-bottom: 0 !important;
    }
    .errorText {
      margin-bottom: 20px;
      span {
        color: $errorRed !important;
        font-weight: 600;
      }
    }
    .under-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        text-decoration: underline !important;
        color: $semiPrimary !important;
      }
    }
  }
}

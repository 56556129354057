.chart-date {
  margin-bottom: 20px;
  span {
    font-size: $body;
    font-weight: 400;
    margin-right: 10px;
  }
  .chart-date-container {
    display: flex;
    align-items: left;
    span {
      margin: auto;
      min-width: 80px;
    }
    .date-chart {
      height: 70px;
      min-height: 70px;
      // border-radius: $radiusXs;
      width: 100%;
      display: flex;
      align-items: center;
      padding-right: 0;
      margin-right: 0;
      .chart {
        canvas {
          width: 700px !important;
        }
      }
      // &.disabled {
      //   cursor: not-allowed;
      //   .chart {
      //     pointer-events: none;
      //     canvas {
      //       opacity: 0.4;
      //     }
      //   }
      // }
    }
    button {
      margin-left: -50px;
    }
  }
  .input-container-isLance-false {
    display: flex;
    // padding-top: 50px;
    margin-bottom: 10px;
    .input-title {
      min-width: 120px;
      text-align: center;
      font-size: $bodySm;
      display: flex;
      align-items: center;
      max-height: 48px;
      margin-bottom: 24px;
      justify-content: center;
    }
    .line {
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 48px;
      margin-bottom: 24px;
    }
    input {
      margin-right: 10px;
    }
    &.end {
      margin-bottom: 0;
    }
    button {
      margin-left: 10px;
      width: 70px;
    }
  }
  .input-container-isLance-true {
    display: flex;
    padding-top: 50px;
    margin-bottom: 10px;
    .input-title {
      min-width: 120px;
      text-align: center;
      font-size: $bodySm;
      display: flex;
      align-items: center;
      max-height: 48px;
      margin-bottom: 24px;
      justify-content: center;
    }
    .line {
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 48px;
      margin-bottom: 24px;
    }
    input {
      margin-right: 10px;
    }
    &.end {
      margin-bottom: 0;
    }
    button {
      margin-left: 10px;
      width: 70px;
    }
  }
}

.line-graph-tooltip {
  position: absolute;
  border-style: solid;
  white-space: nowrap;
  z-index: 3;
  will-change: transform;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 2px 10px;
  transition: opacity 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s,
    visibility 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s,
    transform 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  background-color: $white;
  border-width: 1px;
  border-radius: 8px;
  border-color: $primary;
  color: rgb(102, 102, 102);
  font: 1px / 4px sans-serif;
  padding: 2.5px;
  margin-left: 15px;
  display: flex;
  .collapse-button {
    span {
      font-size: 10px;
      font-weight: bold;
    }
  }
}

.tooltip-table {
  border-collapse: collapse;
  border: 0px solid $border;
  text-align: center;
  margin-right: 4px;
  .head {
    background-color: $primary;
    padding: 5px;
    border: 0px solid $border;
    color: $white;
    font-size: 12px;
    flex: 1;
    max-height: 32px;
    height: 15px;
  }
  .col {
    padding: 5px;
    border: 0.01px solid $border;
    color: $black;
    font-size: 12px;
    flex: 1;
    height: 15px;
  }
  tr {
    td {
      height: 10px;
      padding: 5px;
    }
  }
}

.tooltip-line {
  position: absolute;
  width: 2px;
  z-index: 1;
  background-color: #4382cc !important;
  opacity: 0.5;
}

.user-list {
  .user-list-filter {
    padding: 15px;
    padding-bottom: 0;
    border-radius: $radius;
    border: 1px solid $black;
    margin-bottom: 15px;
    .filter-container {
      display: flex;
      flex-wrap: wrap;
      .filter-item {
        display: flex;
        align-items: center;
        margin-right: 25px;
        margin-bottom: 15px;
        .title {
          font-size: $body;
          margin-right: 15px;
          word-break: keep-all;
        }
        &.end {
          margin-bottom: 0;
        }
      }
      .filter-button {
        margin-left: auto;
        margin-bottom: 15px;
      }
    }
  }
  .user-list-table {
    .userid-item {
      min-width: 80px;
      font-size: $bodySm;
    }
    .fullName-item {
      min-width: 80px;
      font-size: $bodySm;
    }
    .birth-item {
      min-width: 80px;
      font-size: $bodySm;
    }
    .genderage-item {
      min-width: 60px;
      font-size: $bodySm;
    }
    .height-item {
      min-width: 60px;
      font-size: $bodySm;
    }
    .date-item {
      min-width: 100px;
      font-size: $bodySm;
    }
    .number-item {
      min-width: 60px;
      font-size: $bodySm;
    }
    .action-item {
      display: flex;
      justify-content: flex-end;
      // max-width: 100px;
      min-width: 90px;
      button {
        white-space: normal !important;
        max-width: 90px;
        span {
          max-width: 90px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
}

.contractor-detail {
  .ant-descriptions {
    max-width: 600px;
  }
  .ant-descriptions-row {
    .ant-descriptions-item-label {
      max-width: 150px !important;
      background-color: $primary;
      color: $white;
    }
  }
  .ant-descriptions-bordered {
    border: 1px solid black;
  }
}

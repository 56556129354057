@media (min-width: 960px) {
  .chartWidth { width: 85%;}
}

@media (max-width: 960px) {
  .chartWidth { width: 70%;}
  .home-container {
    .home-header {
      padding-left: 0px !important;
      .trigger {
        display: inline !important;
      }
    }
    .home-layout {
      .home-sider {
        left: -170px !important;
      }
      .home-content {
        margin-left: 0 !important;
      }
      &.mobilecollapsed {
        .home-sider {
          left: 0 !important;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .chartWidth { width:50%;}
  .home-container {
    .home-header {
      padding-right: 0 !important;
      h1 {
        font-size: $bigSm !important;
      }
      .header-buttons {
        display: none !important;
      }
      .header-folded-buttons {
        display: flex !important;
      }
    }
  }
}

@media (max-width: 540px) {
  .login {
    .login-container {
      padding: 40px 30px !important;
      img {
        margin-bottom: 30px !important;
      }
    }
  }
}

@media (max-width: 465px) {
  .user-list {
    .user-list-filter {
      .filter-container {
        .filter-item {
          margin-right: 0 !important;
          .title {
            font-size: $bodySm !important;
          }
        }
        .filter-button {
          margin-left: 0 !important;
          width: 100%;
        }
      }
    }
  }
  .home-container {
    .home-layout {
      .home-content {
        padding: 15px !important;
      }
    }
  }
}

@media (max-width: 395px) {
  .chartWidth { width:20%;}
  .login {
    padding: 15px !important;
    .login-container {
      padding: 30px 20px !important;
      img {
        width: 100% !important;
        height: auto !important;
        margin-bottom: 20px !important;
      }
    }
  }
  .user-list {
    .user-list-filter {
      .filter-container {
        .filter-item {
          width: 100%;
          align-items: flex-start !important;
          flex-direction: column !important;
          margin-bottom: 10px !important;
          .title {
            margin-bottom: 5px;
          }
          .ant-select {
            width: 100% !important;
          }
          .ant-picker-range {
            width: 100% !important;
          }
        }
      }
    }
  }
}

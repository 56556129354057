.home-container {
  min-height: 100vh !important;
  .home-header {
    background-color: $primary;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed !important;
    left: 0;
    top: 0;
    right: 0;
    z-index: 2;
    height: 65px;
    position: relative;
    .trigger {
      height: 100%;
      padding: 5px 20px;
      font-size: $title;
      display: none;
    }
    h1 {
      display: flex;
      align-items: center;
      font-size: $bigMd;
      margin-bottom: 0;
      font-weight: bold;
      color: $white;
    }
    .logo {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @media (max-width: 950px) {
        left: 40%;
        transform: translate(-40%, -50%);
      }
      @media (max-width: 820px) {
        display: none;
      }
    }
    .header-buttons {
      display: flex;
      align-items: center;
      button {
        color: $white !important;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .header-folded-buttons {
      display: none;
      padding: 5px 20px;
      .folded-icon {
        color: $white;
        font-size: $bigXs;
      }
    }
  }
  .home-layout {
    position: relative;
    margin-top: 65px;
    background-color: $white !important;
    .sider-overlay {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: none;
      &.on {
        display: flex;
      }
    }
    .home-sider {
      background-color: #ded7c5 !important;
      position: fixed;
      top: 65px;
      bottom: 0;
      left: 0;
      z-index: 1;
      min-width: 170px !important;
      max-width: 170px !important;
      width: 170px !important;
      z-index: 2 !important;
      overflow: hidden !important;
      .menu {
        background-color: transparent !important;
        font-size: $body !important;

        width: 500px;
      }
      .ant-menu-inline,
      .ant-menu-vertical,
      .ant-menu-vertical-left {
        border-right: 0 solid transparent !important;
      }
      .ant-menu-item-selected {
        color: $black !important;
        text-decoration: underline !important;
      }
      .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: transparent !important;
      }
      .ant-menu-vertical .ant-menu-item::after,
      .ant-menu-vertical-left .ant-menu-item::after,
      .ant-menu-vertical-right .ant-menu-item::after,
      .ant-menu-inline .ant-menu-item::after {
        border-right: 0 solid transparent;
      }
      .ant-menu-vertical .ant-menu-item,
      .ant-menu-vertical-left .ant-menu-item,
      .ant-menu-vertical-right .ant-menu-item,
      .ant-menu-inline .ant-menu-item,
      .ant-menu-vertical .ant-menu-submenu-title,
      .ant-menu-vertical-left .ant-menu-submenu-title,
      .ant-menu-vertical-right .ant-menu-submenu-title,
      .ant-menu-inline .ant-menu-submenu-title {
        padding: 20px !important;
      }
    }
    .home-content {
      padding: 20px;
      margin-left: 170px;
      .title {
        .title-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 15px;
          // flex-wrap: wrap;
          overflow-x: auto !important;
          overflow: hidden;
          word-break: keep-all;
          .title-text {
            position: relative;
            h1 {
              font-size: $bigXs;
              margin-bottom: 0;
              font-weight: bold;
              color: $black;
            }
          }
          .button-container {
            .header {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              button {
                color: $primary;
                padding: 4px 8px !important;
                span {
                  text-decoration: underline;
                }
                &:hover {
                  opacity: 0.8;
                }
              }
            }
            .list-buttons {
              display: flex;
              .ant-radio-group {
                display: flex !important;
              }
              .ant-btn-icon-only {
                width: 24px !important;
              }
              .swap {
                padding: 0 !important;
                height: 24px !important;
                margin-right: 5px;
              }
              .download {
                margin-left: auto;
                display: flex;
                flex-wrap: nowrap;
              }
              .measure {
                margin-right: 10px;
              }
              .measureOverlay {
                margin-right: 10px;
              }
            }
          }
          &.list {
            justify-content: flex-start;
            margin-bottom: 10px;
            .ant-select {
              margin-right: 10px;
            }
            .title-text {
              padding-left: 19px;
              h1 {
                font-size: $body;
                font-weight: 400;
                margin-right: 10px;
                position: relative;
              }
              &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background-color: $black;
              }
            }
            .button-container {
              flex: 1;
            }
          }
          &.collapsed {
            h1 {
              cursor: pointer;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -khtml-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
            }
          }
        }
        .title-body-container {
          margin-bottom: 20px;
        }
      }
    }
    &.collapsed {
      .home-sider {
        left: -170px;
      }
      .home-content {
        margin-left: 0;
      }
    }
  }
}

.ant-tabs-tab {
  padding: 15px !important;
  background-color: $border !important;
  color: $white !important;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 !important;
}

.ant-tabs-tab-active {
  background-color: $primary !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $white !important;
}

.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 !important;
}

.ant-tabs-nav-wrap {
  border-bottom: 0px solid transparent !important;
}

.user-detail {
  .user-list-table {
    .userid-item {
      min-width: 80px;
      font-size: $bodySm;
    }
    .fullName-item {
      min-width: 80px;
      font-size: $bodySm;
    }
    .birth-item {
      min-width: 80px;
      font-size: $bodySm;
    }
    .genderage-item {
      min-width: 60px;
      font-size: $bodySm;
    }
    .height-item {
      min-width: 60px;
      font-size: $bodySm;
    }
    .date-item {
      min-width: 100px;
      font-size: $bodySm;
    }
    .number-item {
      min-width: 60px;
      font-size: $bodySm;
    }
    .action-item {
      display: flex;
      justify-content: flex-end;
      // max-width: 100px;
      min-width: 90px;
      button {
        white-space: normal !important;
        max-width: 90px;
        span {
          max-width: 90px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
  .reception-table {
    .table-mw-100 {
      min-width: 100px;
      font-size: $bodySm;
    }
    .table-mw-80 {
      min-width: 80px;
      font-size: $bodySm;
    }
    .table-mw-60 {
      min-width: 60px;
      font-size: $bodySm;
    }
    .action-item {
      display: flex;
      justify-content: flex-end;
      // max-width: 100px;
      min-width: 90px;
      button {
        white-space: normal !important;
        max-width: 90px;
        span {
          max-width: 90px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
  .summary-table {
    .table-mw-100 {
      min-width: 100px;
      font-size: $bodySm;
    }
    .table-mw-80 {
      min-width: 80px;
      font-size: $bodySm;
    }
    .table-mw-60 {
      min-width: 60px;
      font-size: $bodySm;
    }
  }
  .table-row-view {
    background-color: #cadae0;
  }
}

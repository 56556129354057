// Default
$black: #000;
$white: #fff;

// App Color
$primary: #0055a1;
$semiPrimary: #3f8cd1;
$semiPrimaryDark: #6886a0;
$primaryDark: #093962;
$errorRed: #f73131;
$border: #d0d0d0;

// Global Sizes
$radius: 10px;
$radiusXs: 5px;
$radiusSm: 15px;
$radiusMd: 20px;
$radiusLg: 50px;
$radiusXl: 100px;
$radius50: 50%;

// Font size
$extra: 60px;
$extraLg: 54px;
$extraMd: 50px;
$extraSm: 45px;
$extraXs: 40px;
$large: 34px;
$largeMd: 32px;
$largeSm: 30px;
$largeXs: 28px;
$big: 26px;
$bigMd: 24px;
$bigSm: 22px;
$bigXs: 20px;
$title: 18px;
$titleSm: 17px;
$body: 16px;
$bodyMd: 15px;
$bodySm: 14px;
$caption: 13px;
$small: 12px;
$tiny: 10px;

// Shadow
$box-shadow: rgb(38 78 118 / 12%) 0px 4px 10px;
$box-shadow-lg: rgb(0 0 0 / 10%) 0px 15px 35px;
$box-shadow-logo: rgb(0 0 0 / 10%) 1px -2px 35px 10px;
$box-shadow-header: 0 2px 5px rgba(0, 0, 0, 0.1);

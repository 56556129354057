@import 'style.scss';

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: 'Noto Sans JP', sans-serif !important;
  margin: 0px;
  font-size: 13px;
  line-height: 1.53846;
  color: rgb(0, 0, 0);
  height: 100%;
  background: rgb(255, 255, 255) !important;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  text-size-adjust: 100%;
  word-wrap: break-word !important;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
}

.ant-btn {
  border-radius: $radiusXs !important;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  background: $border !important;
  color: $white !important;
}
